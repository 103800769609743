import React from 'react';
import * as ReactDOM from 'react-dom';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { process } from '@progress/kendo-data-query';
import { ExcelExport, ExcelExportColumn, ExcelExportColumnGroup } from '@progress/kendo-react-excel-export';
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { CustomColumnMenuNoGrp } from './customColumnMenuNoGrp';
import {
  Grid,
  GridColumn as Column,
  GridGroupCell,
  GridToolbar,
} from '@progress/kendo-react-grid';

import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from '@progress/kendo-react-data-tools';
import {
  Sparkline,
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartSeriesDefaults,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
  LegendItemClickEvent,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Moment from 'react-moment';
import Enumerable from 'linq';
import "hammerjs";
import { Modal } from 'react-bootstrap';
import { GridPDFExport, PDFExport } from "@progress/kendo-react-pdf";


import { FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { DropdownButton } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import BankLogoPage from './bankLogoPage';
const PageTemplate = (props) => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: "1px",

          width: "98%",
          borderBottom: "1px solid #bce8f1"

        }}
      >
        <div className='row d-flex mx-3'>
          <div className='col text-start'>
            <a className='px-2'  ><BankLogoPage /></a>

          </div>
          <div className='col text-end px-5 py-2'>
            <h2 className='fw-bold text-fitek'  >Fixed Income Fundamentals Report</h2>

          </div>

        </div>




        {/* <div className='fw-bold  text-center'>
                  Account Profile</div> */}
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
          //  borderTop: "1px solid #bce8f1"
        }}
      >
        Page {props.pageNum} of {props.totalPages}
      </div>
    </div>
  );
};



const aggregates = [

];

const initialGroup = [
  {
    field: "mtrtyYr",
  }
];

const processWithGroups = (data, dataState) => {
  // debugger;
  const groups = dataState.group;

  // if (groups) {
  //   groups.map((group) => (group.aggregates = aggregates));
  // }

  dataState.group = groups;
  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};

const FixdIncmFundmntlsGrid = ({ data, flag, chkChkdFlg }) => {
  debugger;
  const gridRef = useRef();
  const _export = React.useRef(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save(data);
    }
  };

  const clearFIGrid = () => {
    let FiIntData = JSON.parse(localStorage.getItem("FIInitialData"));
    setResult(processWithGroups(FiIntData, dataState));
    setSelectedData(FiIntData);
  };

  const [locked, setLocked] = React.useState(false);

  const columnLocked = () => {
    setLocked(!locked);
  };

  const RightNameHeader = (props) => {
    return (
      <a className="k-link" style={{
        float: "right",
      }} onClick={props.onClick}>
        {/* <span className="k-icon k-i-cart" /> */}
        <span
          style={{
            // color: "#53d2fa",
          }}
        >
          {props.title}
        </span>
        {props.children}
      </a>
    );
  };

  // const columns = [
  let defaultColumnsUnchkd = [
    {
      title: 'Maturity Year',
      field: 'mtrtyYr',
      minWidth: 140,
      show: true,
      filter: 'numeric',
      locked: true,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Description',
      field: 'astShrtNm',
      minWidth: 300,
      show: true,
      filter: 'text',
      locked: false,
    },
    {
      title: 'Shares',
      field: 'shares',
      minWidth: 150,
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Market($)',
      field: 'market',
      minWidth: 150,
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'YTM%',
      field: 'yldToMtrty',
      minWidth: 100,
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Duration To Maturity',
      field: 'duration',
      minWidth: 160,
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Current Yield%',
      field: 'yield',
      minWidth: 150,
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Moody Rating',
      field: 'moodyRating',
      minWidth: 150,
      show: true,
      filter: 'text',
      locked: false,
    },
    {
      title: 'SP Rating',
      field: 'spRating',
      minWidth: 150,
      show: true,
      filter: 'text',
      locked: false,
    }
  ];

  // const columns1 = [
  let defaultColumnsChkd = [
    {
      title: 'Maturity Year',
      field: 'mtrtyYr',
      minWidth: 140,
      show: true,
      filter: 'numeric',
      locked: true,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Description',
      field: 'astShrtNm',
      minWidth: 300,
      show: true,
      filter: 'text',
      locked: false,
    },
    {
      title: 'Shares',
      field: 'shares',
      minWidth: 150,
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Market($)',
      field: 'market',
      minWidth: 150,
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'YTM%',
      field: 'yldToMtrty',
      minWidth: 100,
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'YTW%',
      field: 'yldCalPut',
      minWidth: 100,
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Duration To Maturity',
      field: 'duration',
      minWidth: 160,
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Duration To Call/Put',
      field: 'calPutDuration',
      minWidth: 160,
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Current Yield%',
      field: 'yield',
      minWidth: 150,
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Moody Rating',
      field: 'moodyRating',
      minWidth: 150,
      show: true,
      filter: 'text',
      locked: false,
    },
    {
      title: 'SP Rating',
      field: 'spRating',
      minWidth: 150,
      show: true,
      filter: 'text',
      locked: false,
    }
  ];

  const [ChkBoxState, setChkBoxState] = useState(chkChkdFlg);
  let loadedColumns = localStorage.getItem('gridColumns');
  let loadedColumnsChkd = localStorage.getItem('gridColumnsChkd');

  const GridColumns = flag ? JSON.parse(loadedColumns) : defaultColumnsUnchkd;
  const GridColumnsChkd = flag ? JSON.parse(loadedColumnsChkd) : defaultColumnsChkd;
  const [row, setRow] = useState(data);
  const createDataState = (dataState) => {
    return {
      result: process(data, dataState),
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: 20,
    skip: 0,
    sort: [{ field: 'mtrtyYr', dir: 'asc' }],
  });

  const [result, setResult] = React.useState(
    processWithGroups(data, initialState.dataState)
  );

  const [dataState, setDataState] = React.useState(initialState.dataState);
  const [stateColumns, setStateColumns] = React.useState(GridColumns);
  const [stateColumnsChkd, setStateColumnsChkd] = React.useState(GridColumnsChkd);
  const [currentColumns, setCurrentColumns] = React.useState('');
  const [chartData, setSelectedData] = React.useState(data);
  let pageSize = 20;
  const [page, setPage] = React.useState({
    skip: 0,
    take: pageSize,
  });
  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);

    setResult(processWithGroups(data, updatedState.dataState));

    setDataState(updatedState.dataState);
  };

  //GRID REORDER/RESIZE INIT SETTING
  const onColumnReorder = (props) => {
    (ChkBoxState ?
      setStateColumnsChkd(addHiddenColumns(props.columns))
      :
      setStateColumns(addHiddenColumns(props.columns))
    );
    setCurrentColumns(addHiddenColumns(props.columns));
  };

  const onColumnResize = (props) => {
    (ChkBoxState ?
      setStateColumnsChkd(addHiddenColumns(props.columns))
      :
      setStateColumns(addHiddenColumns(props.columns))
    );
    setCurrentColumns(addHiddenColumns(props.columns));
  };

  const addHiddenColumns = (columns) => {
    let newColumnsState = (ChkBoxState ? stateColumnsChkd : stateColumns).map((col) => {
      let _col = columns.filter((c) => c.field == col.field);
      if (_col.length > 0) {
        return {
          ...col,
          orderIndex: _col[0].orderIndex ? _col[0].orderIndex : -1,
          width: _col[0].width ? _col[0].width : '',
        };
      } else {
        return { ...col, show: false };
      }
    });
    return newColumnsState;
  };

  const onColumnsSubmit = (columnsState) => {
    (ChkBoxState ?
      setStateColumnsChkd(columnsState)
      :
      setStateColumns(columnsState)
    )
    setCurrentColumns(columnsState);
  };
  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };

  const handleColumnLockToggle = (columnField, state) => {
    let newColumns = currentColumns.map((column) => {
      if (column.field === columnField) {
        column.locked = state;
      }

      return column;
    });
    setCurrentColumns(newColumns);
  }; // place all locked columns first

  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setResult({ ...result });
  };

  const cellRender = (tdElement, cellProps) => {


    if (cellProps.rowType === "data") {
      let cpnRate = "", matrtyDate = "";

      // if(cellProps.field==="mtrtyYr")
      // {
      //   return (
      //     <td aria-colindex={cellProps.columnIndex} className={'right-align'} role={"gridcell"}>
      //        { formatNumber(cellProps.dataItem[cellProps.field], "###")}
      //     </td>
      // );
      // }

      if (cellProps.field === "shares") {
        return (

          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "market") {
        return (

          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "yldToMtrty") {
        return (
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
            {formatNumber(cellProps.dataItem["yldToMtrty"], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "yldCalPut") {
        return (
          (ChkBoxState === true) ?
            <>
              <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
                {formatNumber(cellProps.dataItem["yldCalPut"], "##,#.00")}
              </td>
            </> :
            <>
            </>
        );
      }
      if (cellProps.field === "duration") {
        return (
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
            {formatNumber(cellProps.dataItem["duration"], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "calPutDuration") {
        return (
          (ChkBoxState === true) ?
            <>
              <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
                {formatNumber(cellProps.dataItem["calPutDuration"], "##,#.00")}
              </td>
            </> :
            <>
            </>
        );
      }
      if (cellProps.field === "yield") {

        return (
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "maturityDt") {

        const value = cellProps.field; {/* this.props.dataItem[this.props.field];*/ }
        return (
          <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {/*  {formatDate(new Date( cellProps.dataItem[value]), "d")}*/}
            <Moment format="MM/DD/YYYY">
              {cellProps.dataItem[value]}
            </Moment>
          </td>
        );
      }
      if (cellProps.field === "astShrtNm") {
        cpnRate = cellProps.dataItem["couponRate"];
        matrtyDate = cellProps.dataItem["maturityDt"];
        return (
          <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {cellProps.dataItem[cellProps.field]}&nbsp;{formatNumber(cpnRate, "##,#.00")}%&nbsp;
            <Moment format="MM/DD/YYYY">
              {matrtyDate}
            </Moment>
            &nbsp;
          </td>
        );
      }
    }

    return tdElement;
  };

  const ShowMaturityCallPut = (e) => {
    debugger;

    localStorage.setItem('chkChkd', e.target.checked);
    if (e.target.checked) {
      setChkBoxState(true);
      const colschkd = localStorage.getItem('gridColumnsChkd')
      setStateColumnsChkd(colschkd === null ? defaultColumnsChkd : stateColumnsChkd);
      setCurrentColumns(stateColumnsChkd);
      // saveColumnsState(colschkd===null ? defaultColumnsChkd : stateColumnsChkd);
    }
    else {
      setChkBoxState(false);
      const colsUnchkd = localStorage.getItem('gridColumns')
      setStateColumns(colsUnchkd === null ? defaultColumnsUnchkd : stateColumns);
      setCurrentColumns(stateColumns);
      // saveColumnsState(colsUnchkd===null ? defaultColumnsUnchkd : stateColumns);
    }
    // debugger;
    // let updatedState = createDataState(dataState);

    setResult(processWithGroups(data, dataState));

    setDataState(dataState);

  };
  const labelContent = (e) => `$${formatNumber(e.value, "##,#.00")}`;
  const FormatLongNumber = ({ value }) => {

    if (value === 0) {
      return 0;
    }
    else {
      // for testing
      //value = Math.floor(Math.random()*1001);

      // hundreds
      if (value <= 999) {
        return value;
      }
      // thousands
      else if (value >= 1000 && value <= 999999) {
        return (value / 1000) + 'K';
      }
      // millions
      else if (value >= 1000000 && value <= 999999999) {
        return (value / 1000000) + 'M';
      }
      // billions
      else if (value >= 1000000000 && value <= 999999999999) {
        return (value / 1000000000) + 'B';
      }
      else
        return value;
    }
  }
  const onRowClick = e => {
    //debugger;
    var actId = e.dataItem.acctId;

    var acctData = Enumerable.from(data).where(w => w.acctId === actId)
      .toArray();

    setSelectedData(acctData);

    setResult(processWithGroups(data, dataState));
    setDataState(dataState);
  };

  let allPDFExport;
  let gridPDFExport = [];
  const [show, setShow] = React.useState(false);
  const showpreview = async () => {
    setShow(true);
  }
  const printPDF = async () => {
    debugger;

    if (allPDFExport !== null) {
      allPDFExport.save();
    }
  };
  const handleClose = () => setShow(false);

  const saveColumnStateDb = async () => {
    //Storing column settings in DB
    debugger;
    let token = JSON.parse(localStorage.getItem('token'));
    let UserId = JSON.parse(localStorage.getItem('userId'));
    let GridId = ChkBoxState ? 3 : 2;//let 2 for Fixed Income Fundamental Grid unchecked 3 checked
    let ColumnSettings = ChkBoxState ? localStorage.getItem('gridColumnsChkd') : localStorage.getItem('gridColumns');
    //let ColumnSettings = ChkBoxState ? stateColumnsChkd : stateColumns;
    const postData = { UserId, GridId, ColumnSettings };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/RTSaveGridColumn/Index',
      postData,
      config
    )
      .then(response => {

        console.log(response);
        const rowData = response.data;
      })
      .catch((error) => {

        return error;
      });
  }
  const saveColumnsState = (columns) => {
    debugger;
    console.log('save called!!');
    //console.log(columns);
    let currentColumnsState = JSON.stringify(columns);
    (ChkBoxState ? localStorage.setItem('gridColumnsChkd', currentColumnsState) : localStorage.setItem('gridColumns', currentColumnsState))
      ;

    saveColumnStateDb();

  };

  useEffect(() => {
    debugger;
    saveColumnsState(ChkBoxState ? stateColumnsChkd : stateColumns);
  }, [currentColumns]);

  // useEffect(() => {
  //   debugger;
  //   saveColumnsState(stateColumns);
  // }, [stateColumns]);
  const CustomGroupHeader = (props) => {
    //debugger;
    return `${props.value}`;
  };
  const grdFim = (
    <Grid
      style={{ height: "600px" }}
      data={result}
      {...dataState}
      onDataStateChange={dataStateChange}
      onColumnReorder={onColumnReorder}
      onColumnResize={onColumnResize}
      expandField="expanded"
      onExpandChange={expandChange}
      cellRender={cellRender}
      sortable={true}
      resizable={true}
      pageable={true}
      reorderable={true}

      pageSize={10}

      groupable={{
        footer: 'visible',
        enabled: false
      }}
    >
      <GridToolbar>
        <FormGroup>
          <FormControlLabel control={<Checkbox name='chkShwMtrtyCall' checked={ChkBoxState} onChange={ShowMaturityCallPut} />} label="Duration to Call" />
        </FormGroup>
      </GridToolbar>
      {(ChkBoxState ? stateColumnsChkd : stateColumns).map(
        (column, idx) =>
          column.show && (
            // <Column
            //   width={setWidth(column.minWidth)}
            //   locked={column.locked}
            //   key={idx}
            //   field={column.field}
            //   title={column.title}
            //   filter={column.filter}
            //   footerCell={column.footerCell}
            //   // cell={NumberCell}
            //   headerClassName={column.headerClassName}
            //   columnMenu={(props) => (
            //     <CustomColumnMenuNoGrp
            //       {...props}
            //       columns={ChkBoxState ? stateColumnsChkd : stateColumns}
            //       onColumnsSubmit={onColumnsSubmit}
            //     />
            <Column
              width={setWidth(column.minWidth)}
              locked={column.locked}
              key={idx}
              field={column.field}
              title={column.title}
              filter={column.filter}
              footerCell={column.footerCell}
              headerClassName={column.headerClassName}
              {...column}
              //cell={NumberCell}
              columnMenu={(props) => (
                <CustomColumnMenuNoGrp
                  {...props}
                  columns={ChkBoxState ? stateColumnsChkd : stateColumns}
                  onColumnsSubmit={onColumnsSubmit}
                />
              )}
            />
          )
      )}
    </Grid>
  );

  return (
    <div>
      <div className="mx-1 px-1 row d-flex justify-content-between align-items-center my-2">
        <div className="col-sm-9 col-md-9 col-lg-9">
          <p className="reportheading fs-6">Fixed Income Fundamentals Report</p>
        </div>

        <div className="col-sm-2 col-md-2 col-lg-2 text-end">
          {/* <button
            className="btn btn-outline-primary btn-sm"
            onClick={clearFIGrid}
          >
            Clear Selection
          </button>&nbsp; */}
          {/* <button
            className="btn btn-outline-primary btn-sm"
            onClick={excelExport}
          >
            Export to Excel
          </button>&nbsp;
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={showpreview}
          >
            Preview
          </button> */}
        </div>
        <div className="col-sm-1 col-md-1 col-lg-1 text-end">
          <DropdownButton id="dropdown-export-button" title="Export" variant='outline-primary' size='sm'>
            <DropdownItem onClick={excelExport}> <span className='px-1'><FaFileExcel /></span>Excel</DropdownItem>
            <DropdownItem onClick={showpreview}><span className='px-1'><FaFilePdf /></span>PDF</DropdownItem>

          </DropdownButton>


        </div>


      </div>
      <Modal show={show} onHide={handleClose} fullscreen={true} size="xl">
        <Modal.Header closeButton><div className="row w-100 tableheader">
          <div className='col'>
            <Modal.Title>Fixed Income Fundamentals Report - Pdf Preview</Modal.Title>
          </div>
          <div className='col text-end'>
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={printPDF}
            >
              Print
            </button>
          </div>


        </div>
        </Modal.Header>
        <Modal.Body>
          <PDFExport
            margin={{ top: 70, left: 30, right: 30, bottom: 40 }}
            pageTemplate={PageTemplate}
            forcePageBreak=".page-break"
            paperSize="A4"
            scale={0.5}
            allPages={true}
            //margin="1cm"
            landscape={true}
            repeatHeaders={true}

            ref={(pdfExport) => (allPDFExport = pdfExport)}>
            <Grid
              style={{ height: '500px' }}
              data={result}
              {...dataState}
              onDataStateChange={dataStateChange}
              expandField="expanded"
              onExpandChange={expandChange}
              sortable={false}
              resizable={false}
              pageable={false}
              groupable={false}
              cellRender={cellRender}
              onRowClick={onRowClick}
              ref={gridRef.current}
              onColumnReorder={onColumnReorder}
              onColumnResize={onColumnResize}
            >
              {(ChkBoxState ? stateColumnsChkd : stateColumns).map(
                (column, idx) =>
                  column.show && (
                    <Column
                      width={setWidth(column.minWidth)}
                      locked={false}
                      key={idx}
                      field={column.field}
                      title={column.title}
                      headerClassName={column.headerClassName}
                      // cell={NumberCell}
                      headerCell={column.headerCell}
                    />
                  )
              )}
            </Grid>
            <br />
            <span className="page-break"></span>
            <Chart style={{ height: "400px" }}>
              {/* <ChartTitle text="Maturity Date Vs Call/Put Date" /> */}
              <ChartLegend position="bottom" />
              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  labels={{
                    visible: true,
                    rotation: 85,
                    format: "d",
                  }}
                />
              </ChartCategoryAxis>
              <ChartValueAxis>
                <ChartValueAxisItem
                  min={0}
                  max={1000000}
                  labels={{
                    visible: true,
                    content: FormatLongNumber,
                    padding: 35,
                  }}
                />
              </ChartValueAxis>
              <ChartSeries>
                <ChartSeriesItem
                  type="column"
                  min={5}
                  data={chartData}
                  field="market"
                  categoryField="mtrtyYr"
                  name='Market Value'
                  labels={{
                    visible: true,
                    content: labelContent,
                  }}
                />
              </ChartSeries>
            </Chart>
          </PDFExport>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary  btn-sm" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <div className="card-body">
        <div className="mx-1 px-1 my-1 py-1">
          
          <ExcelExport data={data} ref={_export} fileName={JSON.parse(localStorage.getItem('AcctSelected')).extrnlAcctId + "_FixedIncomeFundamental.xlsx"}>
            <ExcelExportColumnGroup
              title={"Account Number: " + JSON.parse(localStorage.getItem('AcctSelected')).extrnlAcctId + "  Processing Date: " + localStorage.getItem("processingDate")}
              headerCellOptions={{
                textAlign: "left",
              }}>
              <ExcelExportColumn
                field="tranTypNm"
                hidden={true}
                groupHeader={CustomGroupHeader}
              />
              <ExcelExportColumn field="mtrtyYr" title="Maturity Year" />
              <ExcelExportColumn field="astShrtNm" title="Description" />
              <ExcelExportColumn field="shares" cellOptions={{ format: "#,##0.00", }} title="Shares" />
              <ExcelExportColumn field="market" cellOptions={{ format: "#,##0.00", }} title="Market" />
              {ChkBoxState ? 
              <>
              <ExcelExportColumn field="yldToMtrty" cellOptions={{ format: "#,##0.00", }} title="YTM%" />
              <ExcelExportColumn field="yldCalPut" cellOptions={{ format: "#,##0.00", }} title="YTW%" />
              <ExcelExportColumn field="duration" cellOptions={{ format: "#,##0.00", }} title="Duration To Maturity" />
              <ExcelExportColumn field="calPutDuration" cellOptions={{ format: "#,##0.00", }} title="Duration To Call/Put" />
              </>
:
<>
<ExcelExportColumn field="yldToMtrty" cellOptions={{ format: "#,##0.00", }} title="YTM%" />
<ExcelExportColumn field="duration" cellOptions={{ format: "#,##0.00", }} title="Duration To Maturity" />
</>
}
              <ExcelExportColumn field="yield" cellOptions={{ format: "#,##0.00", }} title="Current Yield%" />
              <ExcelExportColumn field="moodyRating" title="Moody Rating" />
              <ExcelExportColumn field="spRating" title="SP Rating" />
            </ExcelExportColumnGroup>
          </ExcelExport>
          
          {grdFim}
        </div>
        <div className="mx-1 px-1 my-1 py-1 card-body border">
          <Chart style={{ height: "400px" }}>
            {/* <ChartTitle text="Maturity Date Vs Call/Put Date" /> */}
            <ChartLegend position="bottom" />
            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                labels={{
                  visible: true,
                  rotation: 85,
                  format: "d",
                }}
              />
            </ChartCategoryAxis>
            <ChartValueAxis>
              <ChartValueAxisItem
                min={0}
                max={1000000}
                labels={{
                  visible: true,
                  content: FormatLongNumber,
                  padding: 35,
                }}
              />
            </ChartValueAxis>
            <ChartSeries>
              <ChartSeriesItem
                type="column"
                min={5}
                data={chartData}
                field="market"
                categoryField="mtrtyYr"
                name='Market Value'
                labels={{
                  visible: true,
                  content: labelContent,
                }}
              />
            </ChartSeries>
          </Chart>
        </div>
      </div>
      <br />
    </div>
  );
};

export default FixdIncmFundmntlsGrid

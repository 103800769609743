import React from 'react'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Modal from 'react-bootstrap/Modal';
import { FcExpired} from 'react-icons/fc';
import Loading from './loading';
import GridMjrAsset from './gridMjrAsset';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { GridPDFExport, PDFExport, savePDF } from "@progress/kendo-react-pdf";
import axios from 'axios';
import Enumerable from 'linq';
import {
    AutoComplete,
    ComboBox,
    MultiColumnComboBox,
    DropDownList,
    MultiSelect,
    DropDownTree,
} from "@progress/kendo-react-dropdowns";
import { FaFunnelDollar, FaMoneyBill, FaPrint, FaSyncAlt } from 'react-icons/fa';
import { filterBy } from '@progress/kendo-data-query';
import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaSignOutAlt, FaChalkboard, FaListAlt, FaRegChartBar, FaDonate, FaChartLine, FaDice, FaUserAlt, FaCogs } from 'react-icons/fa';
import MjrMnrAssetDetails from './mjrMnrAssetDetails';


const DashboardDetails = ({ data, mjrAllData, mnrAllData, assetAllData, allPerformDtls }) => {
    const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));
    const [dataAcct, setDataAcct] = React.useState(data);
    const [updatedMjrData, setUpdatedMjrData] = useState(mjrAllData);
    const [updatedMnrData, setUpdatedMnrData] = useState(mnrAllData);
    const [updatedAssetData, setUpdatedAssetData] = useState(assetAllData);
    const [updatedPerformAllData, setUpdatedPerformAllData] = useState(allPerformDtls);
    const [loading, setLoading] = useState(false);
    const [availableCash, setAvailableCash] = useState(0);
    const [excludedCash, setExcludedCash] = useState(0);
    const [mrktVlAmt, setMrktVlAmt] = useState(0);
    const [session,setSession]=useState("");
  const navigate = useNavigate();
    var tempToken = JSON.parse(localStorage.getItem('token'));


    useEffect(() => {
        GetUpdatedAccountProfile(selAcct.acctId);
       
    }, [])


    const filterChange = (event) => {
        setDataAcct(filterData(event.filter));
    };

    const filterData = (filter) => {
        const dataAcct = data;
        return filterBy(dataAcct, filter);
    };



    const GetUpdatedAccountProfile = async (AcctId) => {
        setLoading(true);
debugger;
        //let token = JSON.parse(localStorage.getItem('token'));
        let token = tempToken;
        let RoleTypId = JSON.parse(localStorage.getItem('roleId'));
        let UserId = JSON.parse(localStorage.getItem('userId'));
        //let EmailAdrs=JSON.parse(localStorage.getItem('email'));

        const postData = { UserId, RoleTypId, AcctId };
        const config = {
            headers: {
                'authorization': `Bearer ${token.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

        };
        await axios.post('/AdvAccountProfile',
            postData,
            config
        )
            .then(response => {

                //  console.log(response);

                const rowData = response.data;

                let mjrData = Enumerable.from(rowData.t2).where(w => w.mvPercent !== 0)
                    .toArray();
                let mnrData = Enumerable.from(rowData.t3).where(w => w.mvPercent !== 0)
                    .toArray();
                let assetData = Enumerable.from(rowData.t4).where(w => w.mvPercent !== 0)
                    .toArray();

                setUpdatedMjrData(mjrData);
                setUpdatedMnrData(mnrData);
                setUpdatedAssetData(assetData);
                setAvailableCash(rowData.t1[0] === undefined ? 0 : rowData.t1[0].availableCash);
                setExcludedCash(rowData.t1[0] === undefined ? 0 : rowData.t1[0].excludedCash);
                setMrktVlAmt(rowData.t1[0] === undefined ? 0 : rowData.t1[0].mrktVlAmt);
                setUpdatedPerformAllData(rowData.t7);


                setLoading(false);


            })
            .catch((error) => {
                if (error.response.status === 401) {
                    refreshToken();

                }
                // return error;
            });

    }

    const refreshToken = async () => {
        let token = JSON.parse(localStorage.getItem('token'));
        let AccessToken = token.token;
        let RefreshToken = token.refreshToken;
        const postData = { AccessToken, RefreshToken };
        const config = {
            headers: {
                'authorization': `Bearer ${token.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

        };
        await axios.post('/token/Refresh',
            postData

        )
            .then((response) => {
                debugger;
                tempToken = response.data;
                localStorage.setItem('token', JSON.stringify(response.data));

                GetUpdatedAccountProfile(selAcct.acctId);


            })
            .catch((error) => {
                // debugger;
                if(error.code==="ERR_BAD_REQUEST"){
                    setSession("Session Expired");
                  }
                console.log("my error is " + error);
            })

    }

    const handleChange = (event) => {

        if (event.target.value === null) {
            SetselAcct(selAcct);
            GetUpdatedAccountProfile(selAcct.acctId);
        }
        else {
            SetselAcct(event.target.value);
            GetUpdatedAccountProfile(event.target.value.acctId);
            localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
        }


        console.log(selAcct);

    };
    const signOut = () => {
    
        navigate("/");
        let token = JSON.parse(localStorage.getItem('token'));
        const postData = {};
        const config = {
          headers: {
            'authorization': `Bearer ${token.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
      
        };
        axios.post('/token/revoke',
        postData,
        config
        )
          .then((response) => {
          // debugger;
          // localStorage.setItem('token', '');
           //console.log(response);
          // navigate("/");
              
            // if (response.statusText === '') {
             
      
      
            // }
      
           
          })
          .catch((error) => {
            // debugger;
            console.log("my error is " + error);
          })
      
          // let tokenNew={token:''};
          // localStorage.setItem('token',JSON.stringify(tokenNew));
          // localStorage.setItem("AcctSelected",null);
          localStorage.removeItem('token');
          localStorage.clear();
        //firebaseApp.auth.signOut();
      
      }
    if (loading) {
        return (<>
         <div className=''>
                
                <div className='row d-flex justify-content-between align-items-center py-1 px-2 mx-2 bg-light shadow-sm rounded'>
                    <div className='col-md-4 col-lg-5 col-sm-11 text-start'>
                        <span className='px-2'>Account(s)</span>
                        <ComboBox
                            style={{
                                width: "350px",
                            }}
                            data={dataAcct}
                            textField="extrnlAcctId"
                            dataItemKey="acctId"
                            filterable={true}
                            value={selAcct}
                            // placeholder="Your Current Book Of Business"
                            onChange={handleChange}
                            onFilterChange={filterChange}
                        />
                    </div>
                </div>
            </div>
            {session==="Session Expired"?
    
    <Modal show={true}  fullscreen={false} size="sm" ><Modal.Body><div className='row d-flex justify-content-center align-items-center'><><FcExpired size={30}></FcExpired>Your session has expired !</><div className='row d-flex justify-content-center align-items-center'>Please login again.</div><p></p><a className='row d-flex justify-content-center align-items-center btn btn-primary btn-sm' style={{outlineColor:"black",borderBlockColor:"black", backgroundColor:"#0099ff", width:"50px"}} onClick={signOut}>OK</a></div></Modal.Body></Modal>
  :
  <Loading />}
        </>)
      }
    return (
        <div>
            <div className=''>
                {/* <div className="rounded"></div> */}
                <div className='row d-flex justify-content-between align-items-center py-1 px-2 mx-2 bg-light shadow-sm rounded'>
                    <div className='col-md-4 col-lg-5 col-sm-11 text-start'>
                        <span className='px-2'>Account(s)</span>
                        <ComboBox
                            style={{
                                width: "350px",
                            }}
                            data={dataAcct}
                            textField="extrnlAcctId"
                            dataItemKey="acctId"
                            filterable={true}
                            value={selAcct}
                            // placeholder="Your Current Book Of Business"
                            onChange={handleChange}
                            onFilterChange={filterChange}
                        />
                    </div>
                </div>
            </div>
            {/* <div className='row d-flex justify-content-center align-item-center px-2 my-2'>
                <div className='col-sm-10 col-lg-3 card text-left m-1'>
                    <div className='card-body'>
                        <div className='d-block'><FaChartLine /></div>
                        <div className='d-block text-primary'><label>Market Value:</label></div>
                        <div className='d-block'><h4 id='lblMrktVal'>${mrktVlAmt.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h4></div>
                    </div>
                </div>
                <div className='col-sm-10 col-lg-3 card text-left m-1'>
                    <div className='card-body'>
                        <div className='d-block'><FaMoneyBill /></div>
                        <div className='d-block text-primary'><label>Available Cash:</label></div>
                        <div className='d-block'><h4 id='lblAvlCash'>${availableCash.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h4></div>
                    </div>
                </div>
                <div className='col-sm-10 col-lg-3 card text-left m-1'>
                    <div className='card-body'>
                        <div className='d-block'><FaFunnelDollar /></div>
                        <div className='d-block text-primary'><label>Exclude Cash:</label></div>
                        <div className='d-block'><h4 id='lblExcludeCash'>${excludedCash.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h4></div>
                    </div>
                </div>


            </div> */}

   


<MjrMnrAssetDetails data={updatedMjrData} mnrData={updatedMnrData} astData={updatedAssetData} performAllData={updatedPerformAllData} mrktVlAmt={mrktVlAmt} availableCash={availableCash} excludedCash={excludedCash}/>

        </div>
    )
}

export default DashboardDetails

import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SelectControl from './selectcontrol';
import { filterBy } from '@progress/kendo-data-query';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomCalendar } from "./customCalendar";
import Modal from 'react-bootstrap/Modal';
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import { useLocation, useNavigate } from 'react-router-dom';

import { FcExpired} from 'react-icons/fc';
import Loading from './loading';
import Header  from './header';
import DateRange  from './dateRange';
import AcctTransactionGrid from './acctTransactionGrid';
import { formatDate } from '@telerik/kendo-intl';
import { Button } from 'react-bootstrap';
// import "@progress/kendo-theme-material/dist/all.css";
//import "@progress/kendo-theme-default/dist/all.css";
const AcctTransactionRpt = () => {
  var tempToken = JSON.parse(localStorage.getItem('token'));
    const [AcctTransactionRptData, populateAcctTransactionRptData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));
    const [selAcctData, setSelAcctData] = useState(JSON.parse(localStorage.getItem('acctData')).slice());
const [session,setSession]=useState("");
    var date = new Date(localStorage.getItem('processingDate'));
    const navigate = useNavigate();
    date.setMonth(date.getMonth()-12);
    const [frmDate, setfrmDate] = React.useState(date);
    const [isClicked, setIsClicked] = useState(localStorage.getItem('isSearchClicked'));
    const [searchData, setSearchData] = useState(JSON.parse(localStorage.getItem('searchedData')));
    const[firstFlag,setFirstFlag]=useState(0);
    const[isDisabled,setisDisabled]= React.useState(false);
    const [toDate, setToDate] = React.useState(new Date(localStorage.getItem('processingDate')));
    const[flag,setFlag]= React.useState(false);
    var minDate = new Date(localStorage.getItem('processingDate'));
    minDate.setMonth(minDate.getMonth()-60);
    var maxDate = new Date(localStorage.getItem('processingDate'));
    //maxDate.setMonth(maxDate.getMonth());
    const[minFrmDt, setminFrmDt]=React.useState(minDate);
    const[maxFrmDt, setmaxFrmDt]=React.useState(maxDate);
    // const minFrmDt = new Date(2021,8,13);
    // const maxFrmDt = new Date(2022,8,14);
    useEffect(() => {
      
      const fetchData = async () => {
           setLoading(true);
          try {
              //let data = location.state;
  
              let userId = JSON.parse(localStorage.getItem('userId'));// data.Email;
             
              //setEmail(email);
              GetAcctTransactionData();
            
              //  console.log(data);
          } catch (error) {
              console.error(error.message);
          }
  
      }
      fetchData();
  }, [])
  function setDate (val){
    debugger;
    if (val == null)
    {
      setfrmDate(frmDate);
      var newToDate = new Date(frmDate);
      newToDate.setMonth(val.getMonth()+12);
      if(newToDate>=new Date(localStorage.getItem('processingDate'))){
        setToDate(new Date(localStorage.getItem('processingDate')));
      }
      else{
        setToDate(newToDate);
      }

    }
    else
    {
      setfrmDate(val);
      var newToDate = new Date(val);
      newToDate.setMonth(val.getMonth()+12);
      if(newToDate>=new Date(localStorage.getItem('processingDate'))){
        setToDate(new Date(localStorage.getItem('processingDate')));
    }
    else{
      setToDate(newToDate);
    }
  }
    
}
function setTDate (val){
  if ( val == null)
  {
    setToDate(toDate);
    var newStartDate = new Date(toDate);
    newStartDate.setMonth(val.getMonth()-12);
    setfrmDate(newStartDate);

  }
  else
  {
    debugger;
    setToDate(val);
    var newStartDate = new Date(val);
    newStartDate.setMonth(val.getMonth()-12);
    setfrmDate(newStartDate);
  }
}
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem('token'));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/token/Refresh',
      postData

    )
      .then((response) => {
        debugger;
        tempToken = response.data;
        localStorage.setItem('token', JSON.stringify(response.data));
        
        GetAcctTransactionData();


      })
      .catch((error) => {
        if(error.code==="ERR_BAD_REQUEST"){
          setSession("Session Expired");
        }
         debugger;
        console.log("my error is " + error);
      })

  }
  const formSubmit=(event)=>{
    GetAcctTransactionData();
  }
  
  const GetAcctTransactionData = async () => {
    debugger;
    setLoading(true);
    debugger;
     //let token = JSON.parse(localStorage.getItem('token'));
     let token=tempToken;
     let userId = JSON.parse(localStorage.getItem('userId'));
     let acctId= JSON.parse(localStorage.getItem('AcctSelected')).acctId;
     //let startDate = "09/13/2021";
     //let startDate= (localStorage.getItem('processingDate'));
     let startDate=frmDate;
     let endDate=toDate;
     const postData = {userId, startDate,endDate,acctId };
     const config = {
        headers: {
          'authorization': `Bearer ${token.token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      
  };
    await axios.post('/AcctTransactDateRange',
        postData,
       config
    )
        .then(response => {
          debugger;
            //  console.log(response);
 
            const rowData = response.data;
            localStorage.setItem('EndingBal', rowData.endingBal);
            localStorage.setItem('acctType', rowData.accountType);
            localStorage.setItem('Administrator', rowData.administrator);
            populateAcctTransactionRptData(rowData.ocAcctTransaction)
            setFirstFlag(1);
            setFlag(true);
            setLoading(false);
            debugger;
            for(var i=0;i<rowData.ocAcctTransaction.length;i++){
              rowData.ocAcctTransaction[i].prcsDt=formatDate(new Date(rowData.ocAcctTransaction[i].prcsDt),"d")
            }
            localStorage.setItem("filterData",JSON.stringify(rowData.ocAcctTransaction) );
            debugger;
            console.log(i);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken();
  
  
          }
            //return error;
        });
  
  }
  const filterChange = (event) => {
         
    setSelAcctData(filterData1(event.filter));
  };
  const filterData1 = (filter) => {
   
    // const dataAcct = selAcctData.slice();
     return filterBy(JSON.parse(localStorage.getItem('acctData')).slice(), filter);
   };
   const handleChange = (event) => {
    debugger;
      if (event.target.value === null) {
        SetselAcct(selAcct);
        //GetUpdatedAccountProfile(0);
        localStorage.setItem('IsAcctSelected', false);
        
        }
      else {
        
        SetselAcct(event.target.value);
        localStorage.setItem('IsAcctSelected', true);
        localStorage.setItem('AcctSelected', JSON.stringify(event.target.value));
        
        GetAcctTransactionData();
        console.log(selAcct);
        
        
        //GetUpdatedAccountProfile(event.target.value.acctId);
      }
        
    };
    const signOut = () => {
    
      navigate("/");
      let token = JSON.parse(localStorage.getItem('token'));
      const postData = {};
      const config = {
        headers: {
          'authorization': `Bearer ${token.token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
  
      };
      axios.post('/token/revoke',
      postData,
      config
      )
        .then((response) => {
        // debugger;
        // localStorage.setItem('token', '');
         //console.log(response);
        // navigate("/");
            
          // if (response.statusText === '') {
           
  
  
          // }
  
         
        })
        .catch((error) => {
          // debugger;
          console.log("my error is " + error);
        })
  
        // let tokenNew={token:''};
        // localStorage.setItem('token',JSON.stringify(tokenNew));
        // localStorage.setItem("AcctSelected",null);
        localStorage.removeItem('token');
        localStorage.clear();
      //firebaseApp.auth.signOut();
  
    }
  if (loading) {
    return(<>
      {session==="Session Expired"?
      
        <Modal show={true}  fullscreen={false} size="sm" ><Modal.Body><div className='row d-flex justify-content-center align-items-center'><><FcExpired size={30}></FcExpired>Your session has expired !</><div className='row d-flex justify-content-center align-items-center'>Please login again.</div><p></p><a className='row d-flex justify-content-center align-items-center btn btn-primary btn-sm' style={{outlineColor:"black",borderBlockColor:"black", backgroundColor:"#0099ff", width:"50px"}} onClick={signOut}>OK</a></div></Modal.Body></Modal>
      :
      <div>
       
        <Header></Header>
        
        <div className='row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
          <div className='subheader text-end col-md-1'> &nbsp; Account(s):</div>
          <div className='col-md-4 text-start'>
            <ComboBox
              style={{
                width: "350px",
              }}
              data={selAcctData}
              textField="extrnlAcctId"
              dataItemKey="acctId"
              filterable={true}
              value={selAcct}
              onChange={handleChange}
              onFilterChange={filterChange}
            />
          </div>
          {/* <div className='col-md-2'>
            <button className='btn btn-sm btn-outline-secondary px-2' ><FaPrint></FaPrint> &nbsp; Export to PDF</button>
          </div> */}
          </div>

          <div className='row d-flex justify-content-between align-items-center mx-2 px-1 py-2'>
          <div className='reportheading fs-6 col-md-3 col-lg-3 col-sm-10'>Transactions Report</div>
        <div className='col-md-3 col-lg-3 col-sm-10'>
          <span className='py-1'>From</span>
        <DatePicker id="dpFrm"
              value={frmDate}
              format="MM/dd/yyyy"
              calendar={CustomCalendar}
              min={minFrmDt}
              max={maxFrmDt}
              disabled={isDisabled}
              onChange={(e)=>{
                setDate(e.value);
                
              }}
            
            />

        </div>

        <div className='col-md-3 col-lg-3 col-sm-10'>
        <span className='py-1'>To</span>
        <DatePicker
              //defaultValue={toDate}
              value={toDate}
              format="MM/dd/yyyy"
              calendar={CustomCalendar}
              min={minFrmDt}
              max={maxFrmDt}
              disabled={false}
              onChange={(e)=>{
                setTDate(e.value);
                
              }}
            />

        </div>

        <div className='col-md-2 col-lg-2 col-sm-10'>

        <input type="submit"  className='btn btn-primary btn-sm' value="Submit"/> 

        </div>

      </div>
    
      <Loading />
            
   </div>}</>
    )
  }
  
    return (
      <div>
        <Header></Header>
        <form onSubmit={formSubmit}  >
        <div className='row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
                    <div className='col-md-4 col-lg-5 col-sm-11 text-start'>
                        <span className='px-2'>Account(s)</span>
            <ComboBox
              style={{
                width: "350px",
              }}
              data={selAcctData}
              textField="extrnlAcctId"
              dataItemKey="acctId"
              filterable={true}
              value={selAcct}
              onChange={handleChange}
              onFilterChange={filterChange}
            />
          </div>
          
          {/* <div className='col-md-2'>
            <button className='btn btn-sm btn-outline-secondary px-2' ><FaPrint></FaPrint> &nbsp; Export to PDF</button>
          </div> */}
          </div>


          <div className='row d-flex justify-content-between align-items-center mx-2 px-1 py-2'>
          <div className='reportheading fs-6 col-md-3 col-lg-3 col-sm-10'>Transactions Report</div>
        <div className='col-md-3 col-lg-3 col-sm-10'>
          <span className='py-1'>From</span>
        <DatePicker id="dpFrm"
              value={frmDate}
              format="MM/dd/yyyy"
              calendar={CustomCalendar}
              min={minFrmDt}
              max={maxFrmDt}
              disabled={isDisabled}
              onChange={(e)=>{
                setDate(e.value);
                
              }}
            
            />

        </div>

        <div className='col-md-3 col-lg-3 col-sm-10'>
        <span className='py-1'>To</span>
        <DatePicker
              //defaultValue={toDate}
              value={toDate}
              format="MM/dd/yyyy"
              calendar={CustomCalendar}
              min={minFrmDt}
              max={maxFrmDt}
              disabled={false}
              onChange={(e)=>{
                setTDate(e.value);
                
              }}
            />

        </div>

        <div className='col-md-2 col-lg-2 col-sm-10'>

        <input type="submit"  className='btn btn-primary btn-sm' value="Submit"/> 

        </div>
        
      </div>
      </form>
      {flag?
          <AcctTransactionGrid data={AcctTransactionRptData} />:<></>}
       {/* {firstFlag===1?<DateRange data={AcctTransactionRptData} />:<></>}
         {!isClicked?<AcctTransactionGrid data={AcctTransactionRptData} />:<></>}  */}
       
         {/* <DateRange/> */}
      </div>
    )
  }
  
  export default AcctTransactionRpt
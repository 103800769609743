import React from 'react'
import { useState, useEffect } from 'react'
import "../index.css";

import { Fade } from "@progress/kendo-react-animation";
import { FaKey, FaLock, FaMicrosoft, FaUserAlt, FaEye, FaEyeSlash } from 'react-icons/fa';
import Dashboard from './dashboard';
import Box from '@mui/material/Box';
import { Button } from 'bootstrap';
import axios from 'axios';
import LogoPage from './logoPage';
import TextField from '@mui/material/TextField';
import { msalConfig } from './authConfig';
import { PublicClientApplication } from "@azure/msal-browser";
import Logging from './loginloader';
import { useMsal } from "@azure/msal-react";

import {
  signInWithGooglePopup,
  signInWithMicrosoftPopup,
  createUserDocumentFromAuth,
  firebaseApp, auth
}
  from '../utils/firebase/firebase.utils';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
  Outlet,
  useParams,
  NavLink,
  useNavigate,
  useLocation
} from 'react-router-dom';

import "../index.css";
import { IconButton } from '@mui/material';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
debugger;
// if (window.__RUNTIME_CONFIG__.NODE_ENV != 'development') {

//   axios.defaults.baseURL = window.__RUNTIME_CONFIG__.REACT_APP_PROD_URL;
// }
// else {
//   axios.defaults.baseURL = window.__RUNTIME_CONFIG__.REACT_APP_DEV_URL;
// }

if (process.env.NODE_ENV != 'development') {

  axios.defaults.baseURL = process.env.REACT_APP_PROD_URL;
}
else {
  axios.defaults.baseURL = process.env.REACT_APP_DEV_URL;
}
let clientId = process.env.REACT_APP_CLIENT_ID;
let tenentId = process.env.REACT_APP_TENANT_ID;
let clientSecret = process.env.REACT_APP_CLIENT_SECRET;
const Home = () => {
  debugger;
  const [noAccterror,setnoAccterror]=useState(localStorage.getItem("noAccounts")==="1"?true:false);
  if(localStorage.getItem("noAccounts")==="1"){
 setTimeout(() => {
    setnoAccterror(false);
  }, 5000);
  }
 
  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  //const LockedMsg = location.state;
  const [email, setEmail] = useState('');
  const [pswrdShow, setPswrdShow] = useState(false);
  // User Login info
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [attempt, setattempt] = useState(0);
  const [isLocked, setisLocked] = useState(0);
  const [isMailFailed, setisMailFailed] = useState(0);
  //const [attempt,setattempt]=useState(localStorage.getItem("attempt")?JSON.parse(localStorage.getItem("attempt")):0);
  const [disable, setdisable] = useState(attempt === 5 ? true : false);
  const errors = {
    uname: "invalid username",
    pass: "invalid password"
  };

  useEffect(() => {
    debugger;
    localStorage.setItem("AcctSelected", null);
    let LockedMsg2 = location.state?.lockedmsg;
    if (location.state?.islocked) {
      setisLocked(1);
    }
    //JSON.parse(localStorage.getItem('token'));
    // let isAuth = JSON.parse(localStorage.getItem('tokenMicrosoft'));
    let isAuth = JSON.parse(localStorage.getItem('token'));
    if (isAuth && isAuth !== null) {
      if (isAuth.token && isAuth.token !== null) {
        debugger;
        localStorage.removeItem('token');
        // if(location.state.IsLocked==1)
        //   navigate("/",{ state: location.state });
        //  else
        //  {
        navigate("/");
        //}
      }

    }
  }, [])

  const authorizeandRedirect = (event) => {
    debugger;
    setLoading(true);
    event.preventDefault();
    debugger;
    setisLocked(0);
    var { uname, pass } = document.forms[0];

    if (uname.value == '') {
      setErrorMessages({ name: "uname", message: 'Username cant be blank.' });
    }
    else if (pass.value == '') {
      setErrorMessages({ name: "pass", message: 'Password cant be blank.' });
    }
    else {

      let Username = uname.value;

      let Password = pass.value;

      let IsSso = '0';
      const data = { Username, Password };
      debugger;
      axios.post('/auth/login', data)
        .then((response) => {

          console.log(response);
          debugger;
          if (response.statusText === 'OK' || response.statusText === '') {

            let token = response.data;
            localStorage.setItem("LoggedInFlag", false);
            setisMailFailed(0);
            const postData = { token };
            localStorage.setItem('token', JSON.stringify(token));
            localStorage.setItem('email', JSON.stringify(Username));
            //  localStorage.setItem("AcctSelected",null);
            localStorage.setItem("attempt", null);
            setErrorMessage("");
            navigate("/userToken", { state: postData });
            //navigate("/dashboard", { state: postData });

            //navigate("/dashboard",{state:1});
          }
          setLoading(false);
        })
        .catch((error) => {
          debugger;
          setLoading(false);
          console.log("my error is " + error);
          //if(error.response.data==)
          setErrorMessage(error.response.data);
          if (error.message === "Network Error") {
            setErrorMessage("Network Error")
          }

          // setErrorMessages({ name: "error", message: error.response.data });
          if (error.response.data == "User Locked") {
            setisLocked(1);
          }
          else if (error.response.data == "Mail Service failed") {
            debugger;
            //setErrorMessages({ name: "uname", message: 'Mail Service failed' });
            setisMailFailed(1);
          }
          else {
            //var attempts = attempt + 1;
            //localStorage.setItem("attempt", JSON.stringify(attempts));
            //setdisable(attempts === 5 ? true : false);
            //setattempt(attempts);
            //console.log(attempts);
          }
          navigate("/");
        })

    }

  }
  const { instance } = useMsal();
  const pca = new PublicClientApplication({
    auth: {
      clientId: clientId,
      authority: `https://login.microsoftonline.com/${tenentId}`,
      clientSecret: clientSecret
    }
  });

  const loginRequest = {
    scopes: ["user.read"],
    prompt: 'select_account',
  }

  const logMicrosoftUser = async () => {
    debugger;

    setLoading(true);
    pca.loginPopup(loginRequest)
      .then(response => {
        debugger;
        // setLoading(false);
        if (response.accessToken === '') {
          setErrorMessages({ name: "pass", message: errors.pass });

        }
        else {

          let tokenM = response.accessToken + '==MS';
          localStorage.setItem('tokenMicrosoft', JSON.stringify(tokenM));

          // let email = user.email;
          //let Username=CryptoJS.AES.encrypt(JSON.stringify(email), process.env.REACT_APP_SEC_KEY).toString();
          let Username = response.account.username;
          let Password = tokenM; //process.env.REACT_APP_PASS;

          const data = { Username, Password };

          axios.post('/auth/login', data)
            .then((response) => {

              // console.log(response);
              debugger;
              if (response.statusText === 'OK' || response.statusText === '') {

                let token = response.data;

                const postData = { token };
                localStorage.setItem('token', JSON.stringify(token));
                localStorage.setItem('email', JSON.stringify(Username));
                localStorage.setItem('userId', JSON.stringify(token.userId));
                setLoading(false);
                navigate("/dashboard", { state: postData });

                // navigate("/dashboard",{state:1});
              }

            })
            .catch((error) => {
              // debugger; 
              setLoading(false);
              console.log("my error is " + error);
            })
        }
      })
      .catch(error => {
        setLoading(false);
        debugger;
      });

    debugger;
    //const response=await signInWithMicrosoftPopup();
    // const { user } = await signInWithMicrosoftPopup();
    // // console.log(user);
    // const userDocReference = await createUserDocumentFromAuth(user);
    // //console.log(response);
    // createUserDocumentFromAuth(user);

  }

  // Generate JSX code for error message
  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  const styleLogin = {
    float: 'right'

  };
  const togglePswrdShow = (event) => {
    debugger;
    event.preventDefault();
    setPswrdShow(!pswrdShow);
    return false;
  }
  return (
    <div>
      <NotificationGroup
        style={{
          right: "44%",
          top: "0.2%",
          alignItems: "flex-start",
          flexWrap: "wrap-reverse",
        }}
      >
        <Fade>
          {noAccterror && (
            <Notification
              type={{
                style: "none",
                icon: false,
                
              }}
              
              style={{backgroundColor:"#de0f00", color:"white"}}
              
            >
              
              <span>No Accounts Found.</span>
            </Notification>
             )}
             </Fade>
      </NotificationGroup>
      <div>
        <div className='row'>

          <div>
            {/* <a className="text-end" style={styleLogin}  onClick={handleLogIn}>Microsoft LogIn</a> */}
          </div>
        </div>
      </div>
      <div className="app">
        <div>
          <div className="login-form">
            <div className='text-center'>
              <LogoPage />
            </div>
            <div className='text-center pb-2 pt-2 fs-5'>Sign in</div>
            {/* <div className="title text-center h5 pt-1">Sign In</div> */}
            <div className="form pb-3">
              <form onSubmit={authorizeandRedirect}  >
                <div className="input-container">

                  <div className='input-group pb-2 pt-2'>
                    <span className="input-group-text" id="basic-addon1"><FaUserAlt /></span>
                    {/* <input className='form-control' label='Username' variant='Outlined' type="text" name="uname" required /> */}
                    <TextField
                      required className='form-control' name="uname"
                      variant="outlined" label='Username' size="small"

                    ></TextField>

                  </div>
                  <div className='row mb-1 text-md'>

                    {renderErrorMessage("uname")}
                  </div>

                </div>
                <div className="input-container">

                  <div className='input-group pb-2'>
                    <span className="input-group-text" id="basic-addon2"><FaLock /></span>

                    <TextField
                      required className='form-control' name="pass"
                      variant="outlined" label='Password' type={pswrdShow ? "text" : "password"} size="small"
                    ></TextField>
                    {/* <div><button className='btn btn-outline-primary btn-sm' onClick={togglePswrdShow}>{pswrdShow?<FaEyeSlash/>:<FaEye/>}</button></div> */}
                    {/* <input className='form-control' type="password" name="pass" required /> */}

                  </div>

                  <div className='row mb-1 text-md'>

                    {renderErrorMessage("pass")}
                  </div>
                </div>

                <div className="button-container pt-2">

                  <input type="submit" className='btn btn-primary w-75' value="Sign in"></input>

                </div>
                {/* <hr></hr> */}

              </form>
              <div className='text-center pt-1 mt-1'>
                <p>Or Sign in using</p>


                <input type="button" className='btn btn-outline-primary' onClick={logMicrosoftUser} value='Microsoft' ></input>

              </div>
              {/* {location.state.IsLocked==1?  <div className='error text-center mt-2'>{location.state.LockedMsg}</div>:<></>}   */}
              {/* {isMailFailed > 0 ? <div className='error text-center mt-2'>Mail Service Failed!</div> : <></>}
              {isLocked > 0 ? <div className='error text-center mt-2'>User Locked</div> : <></>} */}
              <div className='error text-center mt-2'>{errorMessage}</div>
              {loading ? <Logging></Logging> : <></>}
              {/* {attempt > 0 ? attempt === 5 ? <div className='error'>Locked</div> : <div className='error'>{5 - attempt} attempts remaining untill system lock.</div> : <></>} */}
            </div>

            <div className='text-center pt-3 text-success p-1 m-1'>
              <FaLock className='' />

            </div>

            <div className='text-muted mt-1 text-center'>Fi-Tek LLC, 2023</div>

          </div>

        </div>

      </div>

    </div>
  )

}

export default Home






